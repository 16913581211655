import React, { useState, useEffect } from "react";
import "./Winners.css";
import {API} from "../../config/axios.config";
import axios from "axios";
import {formatDateString} from "../../CheckStatus/CheckStatus"; // Подключаем стили

const WinnersList = () => {
    const [draws, setDraws] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredWinners, setFilteredWinners] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Для управления dropdown
    const [currentPage, setCurrentPage] = useState(1);
    const winnersPerPage = 8;

    // Данные розыгрышей с 15 участниками
    useEffect(() => {
        const fakeData = [
            {
                date: "11.10.2024",
                winners: [
                    { name: "Иванов Иван Иванович", code: "00008620", prize: "Сертификат на топливо на сумму 100 BYN" },
                    { name: "Сидоров Сергей Викторович", code: "00008621", prize: "Денежный приз в размере 3 500 BYN" },
                    // Добавляем больше победителей
                ],
            },
            {
                date: "18.10.2024",
                winners: [
                    { name: "Петров Петр Петрович", code: "00008622", prize: "Денежный приз в размере 7 000 BYN" },
                    // Добавляем больше победителей
                ],
            },
            // Остальные даты и победители
        ];
        axios.get(`${API}/winners/dates`).then(x => {
            setDraws(x.data.body);
            setSelectedDraw(x.data.body[0]);
        })
        setDraws(fakeData);
    }, []);

    // Обработка поиска
    useEffect(() => {
        if (searchTerm.trim() === "") {
            setFilteredWinners([]);
            return;
        }

        const results = draws.flatMap((draw) =>
            draw?.winners?.filter((winner) =>
                winner?.user?.profile?.surname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                winner?.gameCode.toString().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredWinners(results);
    }, [searchTerm, draws]);

    // Пагинация: расчет победителей на текущей странице
    const indexOfLastWinner = currentPage * winnersPerPage;
    const indexOfFirstWinner = indexOfLastWinner - winnersPerPage;
    const currentWinners = searchTerm
        ? filteredWinners.slice(indexOfFirstWinner, indexOfLastWinner)
        : selectedDraw
            ? selectedDraw.winners.slice(indexOfFirstWinner, indexOfLastWinner)
            : [];

    const totalPages = searchTerm
        ? Math.ceil(filteredWinners.length / winnersPerPage)
        : selectedDraw
            ? Math.ceil(selectedDraw.winners.length / winnersPerPage)
            : 1;

    // Функция для переключения выпадающего списка
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className='winners'>
        <div className='container'>
            <div className="winners-title">
                <p>
                    ПОБЕДИТЕЛИ
                </p>
            </div>
            <div className="winners-description"><p>
                Поздравляем победителей Рекламной игры!
            </p>
            </div>
<div className="winners-image-first">
    <img src={require("../../img/Group 8137.png")} alt="" className="winners-image-first-img"/>
</div>
            <>
                {draws.length > 0 && <div className="winners-container">
                    {/* Даты розыгрышей для ПК версии */}
                    <div className="draws-list desktop-only">
                        {draws.map((draw, index) => (
                            <button

                                key={index}
                                className={`draw-button ${selectedDraw?.date === draw.date ? "active" : ""}`}
                                onClick={() => {
                                    setSelectedDraw(draw);
                                    setCurrentPage(1);
                                }}
                            >
                                Розыгрыш {formatDateString(draw?.riffle).replaceAll("-", ".")}
                            </button>
                        ))}
                    </div>

                    {/* Кнопка для мобильной версии */}
                    <div className="mobile-only">
                        <button className="dropdown-toggle" onClick={toggleDropdown}>
                            Дата розыгрышей {isDropdownOpen ? "−" : "+"}
                        </button>
                        <div className={`dropdown-list ${isDropdownOpen ? "open" : ""}`}>
                            {draws.map((draw) => (
                                <button
                                    key={draw.date}
                                    className={`draw-button ${selectedDraw?.date === draw.date ? "active" : ""}`}
                                    onClick={() => {
                                        setSelectedDraw(draw);
                                        setCurrentPage(1);
                                        setIsDropdownOpen(false); // Закрываем dropdown после выбора
                                    }}
                                >
                                    Розыгрыш {draw.date}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Список победителей */}
                    <div className="winners-list">
                        <input
                            type="text"
                            placeholder="Введите игровой код или фамилию"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                        <div className="table-header">
                            <div className='table-header-item'>ФИО</div>
                            <div className='table-header-item'>Игровой код</div>
                            <div className='table-header-item'>Приз</div>
                        </div>
                        {currentWinners.length > 0 ? (
                            currentWinners.map((winner, index) => (
                                <div key={index} className="winner-item">
                                    <div
                                        className='table-header-item'>{winner?.user?.profile?.surname + " " + winner?.user?.profile?.name + " " + winner?.user?.profile?.lastname}</div>
                                    <div className='table-header-item'>{String(winner.gameCode).padStart(7, '0')}</div>
                                    <div className='table-header-item'>{winner?.prize}</div>
                                </div>
                            ))
                        ) : (
                            <div>Победители не найдены</div>
                        )}

                        <div className="winners-mobile">
                            <div className="table-header">
                            </div>
                            {currentWinners.length > 0 ? (
                                currentWinners.map((winner, index) => (
                                    <div key={index} className="winner-item-mobile">
                                        <div className='table-header-item-mobile-title'>ФИО</div>
                                        <div
                                            className='table-header-item-mobile'>{winner?.user?.profile?.surname + " " + winner?.user?.profile?.name + " " + winner?.user?.profile?.lastname}</div>
                                        <div className='table-header-item-mobile-title'>Игровой код</div>
                                        <div
                                            className='table-header-item-mobile'>{String(winner?.gameCode).padStart(7, '0')}</div>
                                        <div className='table-header-item-mobile-title'>Приз</div>
                                        <div className='table-header-item-mobile'>{winner?.prize}</div>
                                    </div>
                                ))
                            ) : (
                                <div>Победители не найдены</div>
                            )}
                        </div>


                        {/* Пагинация */}
                        <div className="pagination">
                            {Array.from({length: totalPages}, (_, index) => (
                                <button
                                    key={index}
                                    className={`page-button ${index + 1 === currentPage ? "active" : ""}`}
                                    onClick={() => setCurrentPage(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>}
            </>
            <div className="winners-image-second">
                <img src={require("../../img/Group 8138.png")} alt="" className="winners-image-second-img"/>
            </div>
        </div>
        </div>
    );
};

export default WinnersList;
