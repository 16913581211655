import {useEffect, useState} from "react";
import jwtInterceoptor from "../../shared/jwtInterceptor";
import {API, API_URL_ADMIN} from "../../config/axios.config";
import {
    ActionIcon,
    Anchor,
    Avatar,
    Badge,
    Button,
    Group,
    Modal,
    Pagination,
    ScrollArea,
    Select,
    Table,
    Text
} from "@mantine/core";
import {IconGift, IconPencil} from "@tabler/icons-react";
import {formatDate} from "../../components/Personal-account/profile/profile";
import {toast, Toaster} from "sonner";

export const AcceptedPage = () => {

    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [opened, setOpened] = useState(false);
    const [riffle, setRiffle] = useState();
    const [prize, setPrize] = useState();
    const [product, setProduct] = useState();
    const [maxPages, setMaxPages] = useState(0);

    useEffect(() => {
        jwtInterceoptor.get(`${API_URL_ADMIN}/users/accepted?page=${page}`).then(x => {
            setData(x.data.data);
            setMaxPages(x.data.maxPage);
        })
    }, [page])

    const addWinner = () => {
        const body = {
            riffle: riffle,
            gameCode: product.gameCode,
            prize: prize,
            user: {
                id: product.check.user.id
            }
        }
        jwtInterceoptor.post(`${API_URL_ADMIN}/users/winner/add`, body).then(x => {
            toast.success("Приз выдан")
        })
            .catch(x => {
                toast.error("Ошибка при выдаче приза")
            })
    }

    const rows = data?.map((item) => { return (
        <tr key={item.id}>
            <td>
                <Text size="sm" color="dimmed">
                    {item?.check?.user?.profile?.surname + ' ' + item?.check?.user?.profile?.name + " " + item?.check?.user?.profile?.lastname}
                </Text>
            </td>

            <td>
                <Text size="sm" color="dimmed">
                    {item.gameCode ? String(item.gameCode).padStart(7, '0') : <></>}
                </Text>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {formatDate(item?.check?.dateLoad)}
                </Text>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item?.check?.user?.email}
                </Text>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item?.check?.user?.phoneNumber}
                </Text>
            </td>
            <td>
                <Group spacing={0} position="right">
                    <ActionIcon onClick={() => {
                        setOpened(true);
                        setProduct(item)
                    }}>
                        <IconGift size={16} stroke={1.5}/>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    )
    })

    return (
        <div className="check-body">
            <>
                <Modal
                    opened={opened}
                    onClose={() => setOpened(false)}
                    title="Выдача приза"
                >
                    <Select
                        onChange={(e) => setPrize(e)}
                        label="Приз"
                        placeholder="Выбрать"
                        data={[
                            {label : "3500 BYN", value : "3500 BYN"},
                            {label : "7000 BYN", value : "7000 BYN"},
                            {label : "Подарочный сертификат на топливо", value : "Подарочный сертификат на топливо"},
                        ]}
                    />
                    <Select
                        onChange={(e) => setRiffle(e)}
                        label="Дата розыгрыша"
                        placeholder="Выбрать"
                        data={[
                            {label: "11 октября 2024г. в 12:30", value: "2024-10-11T12:30:00"},
                            {label: "18 октября 2024г. в 12:30", value: "2024-10-18T12:30:00"},
                            {label: "25 октября 2024г. в 12:30", value: "2024-10-25T12:30:00"},
                            {label: "01 ноября 2024г. в 12:30", value: "2024-11-01T12:30:00"},
                            {label: "01 ноября 2024г. в 13:00", value: "2024-11-01T13:00:00"},
                            {label: "05 ноября 2024г. в 12:30", value: "2024-11-05T12:30:00"},
                            {label: "15 ноября 2024г. в 12:30", value: "2024-11-15T12:30:00"},
                            {label: "22 ноября 2024г. в 12:30", value: "2024-11-22T12:30:00"},
                            {label: "29 ноября 2024г. в 12:30", value: "2024-11-29T12:30:00"},
                            {label: "29 ноября 2024г. в 13:00", value: "2024-11-29T13:00:00"},
                            {label: "29 ноября 2024г. в 13:30", value: "2024-11-29T13:30:00"}
                        ]}
                    />
                    <br/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button color="success" onClick={addWinner}>
                            Сохранить
                        </Button>
                    </div>
                </Modal>
            </>
            <Toaster />
            <ScrollArea>
                <Table className="check-body" verticalSpacing="sm">
                    <thead>
                    <tr>
                        <th>Участник</th>
                        <th>Игровой код</th>
                        <th>Дата загрузки</th>
                        <th>Почта</th>
                        <th>Телефон</th>
                        <th>Приз</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Pagination page={page} onChange={(e) => setPage(e)} total={maxPages} color="yellow" size="lg" />
                </div>

            </ScrollArea>
        </div>
    )
}